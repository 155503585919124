.get-text-1 {
  transform: translateX(-200%);
}

.get-text-2 {
  transform: translateX(200%);
}

.get-text-1, .get-text-2 {
  transition: transform .5s;

  &.show {
    transform: translateX(0);
  }
}

.contact-team-btn {
  opacity: 0;
  transition: opacity .5s;

  &.show {
    opacity: 100;
  }
}