.mentions-legales {
  h1 {
    text-align: center;
    margin-bottom: 3rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.1rem;
    margin-top: 1.5rem;
  }
}