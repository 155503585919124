/* Bigger Bootstrap custom switches */

.custom-switch{cursor:pointer}

.switch-custom.custom-control-label { /*// added for alignment with the switch*/
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-bottom: 0.1rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  height: 1.8rem;
  width: 3.5rem;    /*// it was 1.75rem before. Sliding way is longer than before.*/
  pointer-events: all;
  border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1.8rem - 4px);   /*// it was calc(1rem - 4px) before. Oval is bigger than before.*/
  height: calc(1.8rem - 4px);  /*// it was calc(1rem - 4px) before. Oval is bigger than before.*/
  background-color: #adb5bd;
  border-radius: 2rem; /*//   it was 0.5rem before. Oval is bigger than before.*/
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.75rem); /*//translateX(0.75rem);*/
  transform: translateX(1.75rem); /*//translateX(0.75rem);*/
}