body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

#home {
  z-index: -1;
}

.hover:hover {
  cursor: pointer;
}

.navbar {
  padding-right: 0;
  padding-left: 0;
}

#navbarNav {
  li {
    margin: 0 .2rem;
    padding: 15px 0;

    a {
      font-size: 0.7rem;
      color: #fff;
      text-decoration: none;
      display: inline-block;
      padding: 10px 10px;
      position: relative;
      font-weight: 500;

      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #cfa14c;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }

      &:hover:after {
        width: 100%;
        left: 0;
      }
    }

    &.active {
      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        display: inline-block;
        padding: 10px 10px;
        position: relative;

        &:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          background: #cfa14c;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 100%;
        }
      }
    }
  }

  form {
    .btn {
      font-size: 0.8rem;
      font-weight: 600;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }

    &#form-callback .btn {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

@media (min-width: 1400px) {
  .navbar {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  #navbarNav {
    li {
      margin: 0 1rem;

      a {
        font-size: .9rem;
      }
    }

    form {
      .btn {
        font-size: 0.9rem;
        padding-left: 3rem;
        padding-right: 3rem;
      }

      &#form-callback .btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}

#navbar-main {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
}

#navbar-fixed {
  position: fixed;
  top: -500%;
  z-index: 10000;
  width: 100%;

  .main-block {
    background-color: rgba(26, 26, 26, 0.85);
  }
}

#navbar-secondary {
  z-index: 10000;
  width: 100%;

  .main-block {
    background-color: #1a1a1a;
  }
}

#navbar-mobile {
  background-color: #1a1a1a;

  .row {
    min-height: 35px;
    max-height: 35px;
  }

  .navbar-toggler {
    width: 30px;
    height: 30px;

    svg {
      color: #fff;
      width: 30px;
      height: 30px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav-container {
    width: 300px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 1rem;
    color: #fff;
    display: block;
    transition: 0.3s;

    &.btn {
      font-size: 0.85rem;
      color: #1a1a1a;
    }
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
}

#form-callback-mobile .dropdown-menu {
  min-width: 18rem;
}

#navbar-mobile-buttons {
  min-height: 35px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;

  a, .btn {
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
  }
}


#home {
  .jumbotron {
    height: 100vh;
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h1 {
      font-size: 3rem;
      margin: 0;
    }
  }
}

#main-results .card-boat .slick-list {
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #1a1a1a !important;
}

.slick-dots li button:before {
  color: #f1e6d2 !important;
  font-size: 13px !important;
  opacity: 1 !important;
}

.get-catam-text {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 950px;
  margin: auto;
  font-size: 1.1rem;
  line-height: 1.3;
  overflow-x: hidden;

  .contact-team-btn {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;

    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 0%;
      position: absolute;
      background: #1a1a1a;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }

    &:hover:after {
      width: 100%;
      left: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.contact-form {
  background-position: bottom 4vw right -5vw;
  background-repeat: no-repeat;
  background-size: 40vw;
  padding: 4rem 0;

  &.only-yacht {
    background-position: bottom 3vw right 5vw;
    background-size: 20vw;
  }

  &-container {
    padding: 0 1.5rem;
    max-width: 850px;
    margin: auto;
  }

  .buttons, .first-block {
    padding: 0 1.5rem;
    max-width: 850px;
    margin: auto;
  }
}

.home-carousel > .slick-list > .slick-track > .swiper-slide > div {
  height: calc(100% - 1.5rem);
}

.btn.animate-find-button[type=button] {
  -webkit-appearance: none;
}

.our-agencies {
  padding-left: 250px;
  padding-right: 250px;
  margin-top: -50px;

  h2 {
    background-color: #1a1a1a;
    width: 450px;
    max-width: 100%;
    color: #fff;
    position: relative;
    top: -2.7rem;
  }
  .header {
    // background-color: $primary;
    font-size: 0.9rem;
    padding-top: 60px;
    padding-bottom: 30px;
  }
  // &.only-yacht {
  //   h2 {
  //     background-color: $primary;
  //   }
  //   .header {
  //     background-color: $darkblue;
  //   }
  // }

}

.our-agencies-title {
  position: relative;
  max-height: 0;
}

.filter-card {
  select, button {
    height: 100%;
  }
}

#search-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16rem;
  width: 25vw;
  margin: auto;
}

@media (min-width: 768px) and (max-width: 991px) {
  .our-agencies .header > .row > div:nth-child(odd) {
    border-right: 1px solid #dee2e6;
  }
}

@media (min-width: 768px) {
  .our-agencies {
    margin-top: -150px;

    .header > .row > div:not(:last-child) {
      border-right: 1px solid #dee2e6;
    }
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .filter-card {
    .btn, .custom-select {
      font-size: .8rem;
    }
  }
}

#get_a_catam > div {
  padding-bottom: 200px;
}

.we-are {
  margin: 100px 200px 50px;
}

#search-hero {
  height: 650px;

  h1 {
    margin-top: 50px;
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  .boat_breadcrumb {
    margin-bottom: 6.5rem;
  }

  .search_block {
    margin-top: 350px;
  }

}

.search-card {
  background-color: transparent;
  border: none;

  #form-finder {
    border: 1px solid rgba(0, 0, 0, .125);
  }

  #options-block {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  #more-options-block {
    background-color: rgba(255, 255, 255, 0.90);
  }
}

footer {
  .head-footer {
    margin: 0 200px 35px 200px;
    padding-top: 60px;
    font-size: 0.9rem;

    a.effect-ul {
      display: inline-block;
      position: relative;
      padding-bottom: 5px;
      margin-bottom: 5px;

      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #6c757d;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }

      &:hover:after {
        width: 100%;
        left: 0;
      }

      &:hover {
        text-decoration: none;
      }
    }

    a {
      text-decoration: none;

      &.h5 {
        font-size: 1rem;
      }
    }

  }

  .copyright {
    font-size: 0.7rem;
  }
}

.custom-select {
  -webkit-appearance: none;
}

.card-boat-specs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  font-size: .9rem;

  img {
    margin: auto;
    height: 40px;
    width: auto;
  }

  li {
    margin-bottom: 1.25rem;
    width: 20%;
    max-width: 20%;
  }
}

#results .result-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (min-width: 750px) {
  #results .result-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1250px) {
  #results .result-col {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (max-width: 1600px) {
  #search-hero {
    .search_block {
      margin-top: 350px;
    }
  }

  .our-agencies {
    padding-left: 50px;
    padding-right: 50px;

    .col-6 {
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
  footer .head-footer {
    margin: 35px 100px;
  }

}

@media screen and (max-width: 1320px) {
  footer .head-footer {
    margin: 35px 15px;
  }

}

@media screen and (max-width: 1200px) {
  .brand-menu {
    min-height: 110px;
    max-height: 110px;
  }
}

@media screen and (max-width: 1100px) {
  .we-are {
    margin: 100px 50px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .our-agencies {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-height: 1050px) and (min-width: 750px) and (max-width: 1050px) {
  #search-hero {
    .search_block {
      margin-top: 250px;
    }
  }
}

@media screen and (max-width: 900px) {
  .we-are {
    margin: 50px 25px 25px;
  }

  .card-boat-specs {
    img {
      height: 35px;
    }
  }
}


@media screen and (max-width: 768px) {
  #get_a_catam {
    &>div {
      padding-top: 60px;
      padding-bottom: 140px;
    }

    h2 {
      font-size: 3rem;
    }
  }

  #home .jumbotron {
    height: 75vh;
  }

  .our-agencies {
    .col-6 {
      margin-bottom: 50px;
    }
  }
  #search-hero {
    .search_block {
      margin-top: 350px;
    }
  }
  #main-results {
    margin-top: 100px;
  }

  .card-boat-specs {
    font-size: .75rem;
  }
}

@media screen and (min-width: 615px) and (max-width: 768px) {
  .search-card {
    #form-finder {
      top: 100px;
    }
  }

}

@media screen and (max-width: 686px) {
  #main-results {
    margin-top: 175px;
  }
}

@media screen and (max-width: 615px) {
  #home {
    .filter-card {
      .search-card {
        #form-finder {
          top: 140px;
        }
      }
    }
  }

  #search-hero {
    .search_block {
      margin-top: 450px;
    }
  }
}

@media screen and (max-width: 575px) {
  footer {
    .head-footer {
      p, ul {
        text-align: center;
      }

      .cap-ocean-img {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #home {
    .jumbotron {
      margin-top: 0px;

      h1 {
        font-size: 2.5rem;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  #home {
    .jumbotron {
      margin-top: 0px;

      h1 {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 458px) {
  #search-hero {
    .search_block {
      margin-top: 400px;
    }

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 2rem;
    }
  }


  #main-results {
    margin-top: 130px;
  }
}

@media screen and (max-width: 350px) {
  #search-hero {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  .filter-card {
    position: absolute;
    bottom: 10rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 1250px;
    max-width: 90vw;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .filter-card {
    .btn, .custom-select {
      font-size: .8rem;
    }
  }
}
