.k-btn-bgwhite{
  background-color:#fff;
  font-size:1rem !important;
  i{font-size:1rem !important;}
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.cursor-default{
  cursor: default !important;
}
