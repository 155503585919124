.k-menu-new{
  position:absolute;
  right:10px;
  background-color :transparent;
  border: 0;
  border-radius: 4px;
  font-size: 15px;
  opacity:0;

  i{
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
  }
}



.k-menu-new:hover i{
  color:#27B7AF;
}



.menu-link:hover .k-menu-new{
  opacity:1;
}