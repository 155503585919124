.single-navbar{
  background-color: #AA843F;
  ul{
    li{
      a{
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        text-decoration: none;
        display: inline-block;
        padding: 10px 10px;
        position: relative;
        &:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          background: #fff;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover:after {
          width: 100%;
          left: 0;
        }
      }
      button{
        background-color: transparent;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        text-decoration: none;
        border: none;
        box-shadow: none;
        cursor: default;
        &:focus{
          outline: none;
        }
      }
      &.active{
        background-color: #1a1a1a;
      }
    }
  }
}

.boat_breadcrumb{
  font-size: 0.8rem;
}

.boat_description {
  .description {
    margin-bottom: 10rem;

    p {
      margin-left: 200px;
      margin-right: 200px;
    }
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  b {
    font-weight: 600;
  }
}

.boat_specs {
  width: 100%;

  &>div {
    position: relative;
    z-index: 1;
    background-color: white;
    width: 100%;
    margin: auto;

    h2 {
      max-width: 100%;
      width: 450px;
      font-weight: 300;
      color: #fff;
      position: absolute;
      margin: auto;
      top: -2.75rem;
      left: 50%;
      transform: translateX(-50%);
      padding: .5rem 3rem;
    }
  }
}

.boat_specs_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  padding: 0;
  margin: 3rem 0;
  flex-wrap: wrap;

  &>li {
    display: flex;

    &>div {
      padding: .5rem;
    }
  }
}

.boat_specs_icon {
  width: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.boat_tech_infos_container {
  padding: 3rem;
}

.boat_tech_infos {
  h2 {
    font-weight: 400;
    height: 5rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  section {
    h3 {
      margin-bottom: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #ffffff;
      font-size: 1.5rem;

      &.right-side{
        border-bottom: 1px solid #000000;
      }
    }

    ul {
      padding-top: 20px;
    }
  }
}

.infos-bar{
  color: #1a1a1a;
}

#gallery{
  margin: 3rem 0;
}

#gallery-show-more{
  color: #000000;
  font-weight: 500;
  //text-transform: uppercase;
  font-size: 1.1rem;
  text-decoration: none;
  display: inline-block;
  padding: 10px 10px;
  position: relative;

  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    background: #000000;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover:after {
    width: 100%;
    left: 0;
  }
}

#gallery-show-less{
  color: #000000;
  font-weight: 500;
  //text-transform: uppercase;
  font-size: 1.1rem;
  text-decoration: none;
  display: inline-block;
  padding: 10px 10px;
  position: relative;
  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    right: 0;
    position: absolute;
    background: #000000;
    transition: width 0.3s ease 0s, right 0.3s ease 0s;
    width: 0;
  }
  &:hover:after {
    width: 100%;
    right: 0;
  }
}

.boat_contact_container {
  padding: 5% 0;
  background-position: bottom 4vw right -5vw;
  background-repeat: no-repeat;
  background-size: 53vw;
}

.boat_contact {
  margin: 1rem;

  .float-left {
    a:hover {
      text-decoration: none;
    }
  }

  .card-header {
    background-color: #fff;
  }

  #contact{
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 50px;
  }

}

.boat_nav {
  text-transform: uppercase;
  padding: .75rem;
}

@media (min-width: 768px) {
  .boat_nav {
    position: -webkit-sticky;
    position: sticky;
    justify-content: center;
    top: 0;
    z-index: 4;
    padding: 0;

    .nav-item {
      padding: .5rem 1rem;
    }
  }

  .boat_specs {
    position: relative;
    max-height: 11rem;

    & > div {
      max-width: 1000px;
    }
  }

  .boat_specs_list {
    max-height: 16rem;

    &>li {
      width: 40%;
    }
  }

  .boat_tech_infos {
    h2 {
      text-align: left;
    }

    &>div {
      &:first-child .boat_tech_infos_container  {
        padding-top: 18rem;
      }

      &:last-child {
        padding-top: 21rem;
      }
    }
  }

  .boat_tech_infos_container {
    max-width: 750px;
  }

  .boat_contact {
    max-width: 600px;
    margin: 1rem auto;
  }
}