.k-card-datatable{
    padding-top :0 !important;

    table{
        thead{
            th{
                text-transform: uppercase;
                color: #666 !important;
            }            
        }
        tbody{
            
            td{
                padding: 0.5rem 1rem !important;
            }
        }
    }
}

.k-input-search{
    max-width: 200px;
}

.dataTables_wrapper .dataTables_pager .dataTables_length .custom-select {
    margin-right: 0.5rem;
}
