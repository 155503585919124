$primary: #aa843f;

// Slick carousel
.agency-carousel {
	height: 100%;
	@media screen and (max-width: 768px) {
		height: 50vh;
	}
	width: 100%;
	height: 100%;
	.slick-list {
		height: 100%;
		.slick-track {
			height: inherit !important;
			display: flex !important;
			.slick-slide {
				height: inherit !important;
				width: 100%;
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					margin-bottom: 30px;
				}
			}
		}
	}
	.slick-dots {
		bottom: 10px !important;
		li.slick-active button:before {
			color: $primary !important;
		}
	}
}

// Agency
#agency-background {
	background: url("/media/bg-sea.jpg");
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
}

#agency-header {
	min-height: 50vh;
	.agency-breadcrumb {
		font-size: 0.8rem;
		padding: 3rem 0 2.6rem 0;
	}
	.title {
		position: relative;
		width: fit-content;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		&-before {
			position: relative;
			font-weight: 200;
		}
		&::before {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			right: 0;
			width: 100%;
			height: 2px;
			background: rgb(65, 65, 65);
			opacity: 0.2;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			right: 0;
			width: 24%;
			height: 2px;
			background: $primary;
			opacity: 0.8;
		}
	}
	.agency-intro {
		padding-top: calc(2rem + 10px);
		padding-bottom: 2rem;
		padding-right: 4rem;
		&-btns {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media screen and (max-width: 1280px) {
				flex-direction: column;
				align-items: flex-start;
			}
			@media screen and (max-width: 992px) {
				flex-direction: row;
			}
			@media screen and (max-width: 768px) {
				flex-direction: column;
				align-items: flex-start;
			}
			.btn {
				padding: 0.6rem 3.4rem;
				letter-spacing: 0.1rem;
				width: 45%;
				@media screen and (max-width: 1280px) {
					width: 75%;
					margin-bottom: 1rem;
				}
				@media screen and (max-width: 992px) {
					width: 45%;
					margin-bottom: 1rem;
				}
				@media screen and (max-width: 768px) {
					width: 100%;
					margin-bottom: 1rem;
				}
			}
		}
	}
}

#agency-info {
	#agency-description {
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 300 !important;
		}
	}
	.card-info {
		z-index: 401;
		.card {
			bottom: -4rem;
		}
	}
	p {
		font-size: 1.1rem;
	}
	.header {
		display: flex;
		position: relative;
		justify-content: center;
		bottom: 1.4rem;
	}
	.header-title {
		text-transform: uppercase;
		background-color: #000;
		color: #fff;
		width: fit-content;
		padding: 0.6rem 1.8rem;
	}
	.title {
		margin: 1.2rem auto;
		position: relative;
		width: fit-content;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		&::before {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			right: 0;
			width: 100%;
			height: 2px;
			background: rgb(65, 65, 65);
			opacity: 0.2;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			right: 0;
			width: 24%;
			height: 2px;
			background: $primary;
			opacity: 0.8;
		}
	}
	.map-marker svg {
		width: 2.8rem;
		height: 2.8rem;
		fill: $primary;
	}
	.info-tel {
		font-weight: 600;
		font-size: 1.4rem;
		letter-spacing: 0.2rem;
		margin-top: 1rem;
		margin-bottom: 1.4rem;
	}
}

#agency-map {
	#map {
		height: 500px;
	}
}

#agency-team {
	background-color: #000;
	padding: 5rem 0;
	color: #fff;
	.team-img {
		width: 12rem;
		height: 12rem;
		object-fit: cover;
		border-radius: 50%;
	}
	.title {
		text-transform: uppercase;
		text-align: center;
		width: auto;
		font-weight: 500;
		padding-bottom: 5rem;
		letter-spacing: 0.1rem;
	}
	.team-name {
		text-transform: uppercase;
		font-size: 1.2rem;
		font-weight: 700;
		color: $primary;
	}
	.team-flags {
		margin: 1rem 0;
		.fi {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
		}
	}
}
