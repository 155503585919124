@import 'keole/table';
@import 'keole/datatable.arrows';
@import 'keole/datatable';
@import 'keole/form';
@import 'keole/form.switch';
@import 'keole/select2';
@import 'keole/aside-nav';
@import 'keole/typography';
@import 'keole/buttons';
@import 'keole/style';
@import 'keole/single-boat';
@import 'keole/animations';
@import 'keole/mentions-legales';
@import 'keole/agency';

@import 'flag-icons/flag-icons';
