/* Add an asterisk to required fields */
label:not([class^="custom-control"]).required:after {
  content: " *";
  color: red;
}
legend.col-form-label.required:after {
  content: " *";
  color: red;
}

/* Align label */
.form-group > label{
  text-align: right;
}
@media (max-width: 768px){
  .form-group label{
    text-align: left;
  }
}

/* Align label for custom radio */
legend.col-form-label{
  text-align: right;
  font-size: 1rem;
  font-weight: 400;
}

fieldset > legend{
  background-color:#eee;
  margin-bottom:20px;
  padding-left:50px
}

/* More condensed */
.form-group {
  margin-bottom: 0.5rem;
}

fieldset>legend{
  font-size: 1.15rem;
  font-weight: 400;
}

a.btn.disabled{
  pointer-events: auto;
  cursor: not-allowed !important;
}

/* Tinymce */
.tox-tinymce {
  border-radius: 4px !important;
}

.k-input-xs{
  max-width: 50px;
}

::placeholder	 { color:#999 !important; font-style: italic !important;}
