/*
 * Sort styling
 */
table.dataTable thead th {
  position: relative;
  background-image: none !important; /* Remove the DataTables bootstrap integration styling */
}

table.dataTable thead th.sorting:before,
table.dataTable thead th.sorting_asc:before,
table.dataTable thead th.sorting_desc:before,table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after
{
  position: absolute;
  display: block;
  font-family: "Font Awesome 5 Free";
}

table.table thead th.sorting:after, table.table thead th.sorting_asc:after, table.table thead th.sorting_desc:after {
  top: 14px !important;
}
table.table thead th.sorting:before, table.table thead th.sorting_asc:before, table.table thead th.sorting_desc:before {
  top: 14px !important;
}

// Icons when initialize datatable
table.dataTable thead th.sorting:before {
  font-weight: 900; /* regular style/weight */
  content: "\f062";
  font-size: 0.8em;
  padding-top: 0.12em !important;
  right: 1.5em !important;
}
table.dataTable thead th.sorting:after {
  font-weight: 900; /* regular style/weight */
  content: "\f063";
  font-size: 0.8em;
  padding-top: 0.12em !important;
  right: 0.5em !important;
}

// Icon when sorting ASC
table.dataTable thead th.sorting_asc:before {
  font-weight: 900; /* regular style/weight */
  content: "\f062";
  font-size: 0.8em;
  padding-top: 0.12em !important;
  right: 1.5em !important;
  //color: #5d78ff;
}
table.dataTable thead th.sorting_asc:after {
  font-weight: 900; /* regular style/weight */
  content: "\f063";
  font-size: 0.8em;
  padding-top: 0.12em !important;
  right: 0.5em !important;
}

// Icon when sorting ASC
table.dataTable thead th.sorting_desc:before {
  font-weight: 900; /* regular style/weight */
  content: "\f062";
  font-size: 0.8em;
  padding-top: 0.12em !important;
  right: 1.5em !important;
}
table.dataTable thead th.sorting_desc:after {
  font-weight: 900; /* regular style/weight */
  content: "\f063";
  font-size: 0.8em;
  padding-top: 0.12em !important;
  right: 0.5em !important;
  //color: #5d78ff;
}

div.dataTables_scrollBody table.dataTable thead th.sorting:after,
div.dataTables_scrollBody table.dataTable thead th.sorting_asc:after,
div.dataTables_scrollBody table.dataTable thead th.sorting_desc:after {
  //content: "";
}

/* In Bootstrap and Foundation the padding top is a little different from the DataTables stylesheet */
table.table thead th.sorting:after,
table.table thead th.sorting_asc:after,
table.table thead th.sorting_desc:after {
  //top: 8px;
}
